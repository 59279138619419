import React, { useState, useEffect } from 'react'
import { kebabCase } from 'lodash'

const Fieldset = ({
  title,
  type,
  name,
  description,
  descriptionPostScript,
  inputs,
  checkedValue,
}) => {
  const [serviceFromSearchParams, setServiceFromSearchParams] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search)
      const selectedService = params.get('service')

      selectedService && setServiceFromSearchParams(selectedService)
    }
  }, [])

  if (type && name) {
    return (
      <div className="[ flex flex-col items-start mt-6 ]">
        <fieldset className="[ flex flex-col w-full ]">
          <span className="[ uppercase font-normal ]">{title}</span>
          {description && (
            <span>
              {description}
              {descriptionPostScript && (
                <small className="[ font-normal ]">
                  <em>&nbsp;{descriptionPostScript}</em>
                </small>
              )}
            </span>
          )}
          <div className="[ form-checkboxes-wrapper mt-2 ]">
            {inputs.map((input, i) => {
              let checked
              if (type === 'checkbox' && (checkedValue || serviceFromSearchParams)) {
                checked =
                  checkedValue === kebabCase(input.value) ||
                  serviceFromSearchParams === kebabCase(input.value)
              }
              if (input.value) {
                return (
                  <label className="[ flex items-start py-1 ]" key={i}>
                    <input
                      className="[ mt-1 mr-4 ]"
                      type={type}
                      name={name}
                      value={input.value}
                      defaultChecked={checked}
                    />
                    {input.label ?? input.value}
                  </label>
                )
              }
              return false
            })}
          </div>
        </fieldset>
      </div>
    )
  }

  return false
}

export default Fieldset
